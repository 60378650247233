
body, div, ul, ol, dl, dt, dd, li, dl, h1, h2, h3, h4{
    margin:0;padding:0;
    font-style:normal;
    /* font:12px/22px "\5B8B\4F53",Arial, Helvetica,sans-serif */
    font-family: Arial, Helvetica, sans-serif;
} 
ol, ul ,li{list-style: none;} 
img {border: 0; vertical-align:middle;} 
body{margin:0;padding:0;}
.clear{clear:both;height:1px;width:100%; overflow:hidden; margin-top:-1px;} 
a{color:#000000;text-decoration:none; cursor: pointer }  
a:hover{color:#BA2636;text-decoration:underline;} 

body{
    color:#000000;
    background:#fafafa; 
    text-align:center;
    /* position: absolute; */
} 