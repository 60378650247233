

.common-list li{
    display: inline-block;
}

.common-list .block{
    padding: 10px 10px;
}

.alink{
    color: #000000;
    /* font-size: 14px; */
    padding-left: 50px;
}
.aicon{
    display: inline-block;
    max-width: 200px;
    height: 18px;
    padding-left: 25px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: top;
    background-size: 18px 18px;
    background-repeat: no-repeat;
}

.aTextBtn{
    padding: 10px 10px;
    color: #000000;
    /* font-size: 14px; */
    display: inline-block;
    max-width: 200px;
    height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: top;
}




.list{
    margin: 2% 2%;
    background-color: #ffffff;
}

.list li{
    margin-bottom: 1%;
    display: inline-block;
}

.list .block{
    padding:10px 10px;
}



.button {
    background: #3498db;
    background-image: -webkit-linear-gradient(top, #3498db, #2980b9);
    background-image: -moz-linear-gradient(top, #3498db, #2980b9);
    background-image: -ms-linear-gradient(top, #3498db, #2980b9);
    background-image: -o-linear-gradient(top, #3498db, #2980b9);
    background-image: linear-gradient(to bottom, #3498db, #2980b9);
    -webkit-border-radius: 28;
    -moz-border-radius: 28;
    border-radius: 28px;
    font-family: Arial;
    color: #ffffff;
    /* font-size: 16px; */
    padding: 12px 30px 12px 30px;
    text-decoration: none;
    display: inline-block;
  }
  
.button:hover {
    background: #2980b9;
    background-image: -webkit-linear-gradient(top, #2980b9, #3498db);
    background-image: -moz-linear-gradient(top, #2980b9, #3498db);
    background-image: -ms-linear-gradient(top, #2980b9, #3498db);
    background-image: -o-linear-gradient(top, #2980b9, #3498db);
    background-image: linear-gradient(to bottom, #2980b9, #3498db);
    color: #ffffff;
    text-decoration: none;
}

@media (min-height:800px) {

    .alink{
        /* font-size: 38px; */
        height: 30px;
        line-height: 30px;
        background-size: 24px 24px;
        padding-left: 30px;
    }

    .aTextBtn{
        /* font-size: 44px; */
        height: 50px;
    }


    .button {
        /* font-size: 24px; */
    }

} 


